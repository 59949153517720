import request from '@/service/lib/request';

export async function activities() {
    return request({
        url: '/drop/activities',
        method: 'get',
    });
}

export async function scenes() {
    return request({
        url: '/drop/scenes',
        method: 'get',
    });
}